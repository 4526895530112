import { FunctionComponent, ChangeEvent, useState, useEffect } from 'react';
import { NavLink, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

import { useApi } from '../context/ApiProvider';
import { useAppSelector, useAppDispatch } from '../store/hooks';
import { resetState, setAuth } from '../store/dashboardSlice';
import { FilteredUser, Location } from 'sparrowhub-client-axios';

import { Button, ButtonType } from './Button';
import { Alert, AlertIcon, AlertType } from './Alert';
import { Heading } from "./Heading";
import { InputField } from './InputField';


type AuthModalProps = {
  type: string
}

export const AuthModal: FunctionComponent<AuthModalProps> = ({ type }) => {
  const navigate = useNavigate();
  const api = useApi();
  const dispatch = useAppDispatch();

  const auth = useAppSelector((state) => state.dashboard.auth);

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const minimumSplashDuration = 1500;

  // methods
  const handleGetCurrentUser = async (autoLogin = false) => {
    if (autoLogin) {
      setIsLoading(true);
    }

    api.currentUser()
      .then((response) => {
        const currentUser: FilteredUser = JSON.parse((response.data as any).data);
        localStorage.setItem('SparrowHub_QueueDashboard_user', `${currentUser.id}`);
        handleGetPartnerLocations(currentUser);
      })
      .catch((error) => {
        setIsLoading(false);

        // if request fails, no current user
        setTimeout(() => {
          dispatch(setAuth({ showSplash: false }));
        }, minimumSplashDuration);
      })
  };

  const handleLogin = async () => {
    setErrorMessage('');
    setIsLoading(true);

    api.loginUser({
      email,
      password
    })
      .then((response) => {
        // clear previous localStorage values
        localStorage.removeItem('SparrowHub_QueueDashboard_location');
        localStorage.removeItem('SparrowHub_QueueDashboard_user');

        // set token timestamp
        localStorage.setItem('SparrowHub_QueueDashboard_tokenDate', `${new Date().getDate()}`);

        handleGetCurrentUser();
      })
      .catch((error) => {
        setIsLoading(false);
        if (error.response && error.response.status === 400) {
          setErrorMessage('Your email or password is incorrect.');
        } else {
          setErrorMessage('An unexpected error has occurred.');
        }
      })
  }

  const handleLogOut = (): void => {
    // clear local storage
    localStorage.removeItem('SparrowHub_QueueDashboard_location');
    localStorage.removeItem('SparrowHub_QueueDashboard_user');

    // save selected store
    const locationCode = auth.locationCode;

    // log out
    api.logoutUser()
      .then(response => {
        dispatch(resetState());
        dispatch(setAuth({ locationCode }));
      })
      .catch(error => {
        console.log('error');
        console.log(error);
      })

    // nav home
    navigate('/login');
  }

  const handleGetPartnerLocations = async (user: FilteredUser): Promise<void> => {
    api.getLocationsByPartnerID(user.partner_id)
      .then((response) => {
        setIsLoading(false);
        const partnerLocations: Array<Location> = JSON.parse((response.data as any).data);

        // check for location in localStorage
        const storedLocationId = localStorage.getItem('SparrowHub_QueueDashboard_location');
        const storedUserId = localStorage.getItem('SparrowHub_QueueDashboard_user');

        // check for locationCode in store
        const storedLocationCode = auth.locationCode;
        const storedLocationFromCode = partnerLocations.find(location => location.code === storedLocationCode);

        if (storedLocationCode !== null && storedLocationFromCode === undefined) {
          // if supplied location code does not match current authed user, log out and reset
          handleLogOut();
        } else if (
          (storedLocationId !== null || storedLocationFromCode !== undefined) &&
          storedUserId !== null &&
          parseInt(storedUserId) === user.id
        ) {
          // else use supplied location or local storage
          let storedLocation = null;
          if (storedLocationFromCode !== undefined) {
            storedLocation = storedLocationFromCode;
          } else if (storedLocationId !== null) {
            storedLocation = partnerLocations.find(location => location.id === parseInt(storedLocationId));
          }
          if (storedLocation) localStorage.setItem('SparrowHub_QueueDashboard_location', `${storedLocation.id}`);
          dispatch(setAuth({
            user: user,
            location: storedLocation,
            partnerLocations: partnerLocations,
            showSplash: false
          }))
          navigate('/queue');
        } else {
          // else proceed to store selection
          dispatch(setAuth({
            user: user,
            partnerLocations: partnerLocations,
            showSplash: false
          }))
          navigate('/select-store');
        }
      })
      .catch((error) => {
        setIsLoading(false);
        console.log('error')
        console.log(error)

        setTimeout(() => {
          dispatch(setAuth({ showSplash: false }));
        }, minimumSplashDuration);
      })
  }

  const handleSelectLocation = (location: Location) => {
    dispatch(setAuth({ location }));
    localStorage.setItem('SparrowHub_QueueDashboard_location', `${location.id}`);
    navigate('/queue');
  }

  useEffect(() => {
    // handle persistent login
    let shouldAutoLogin = false;
    if (
      api && api !== null &&
      auth.user === null &&
      type === 'login'
    ) {
      const tokenDate = localStorage.getItem('SparrowHub_QueueDashboard_tokenDate');
      if (tokenDate !== null) {
        let currentDate = new Date().getDate();

        if (parseInt(tokenDate) === currentDate) {
          shouldAutoLogin = true;
        }
      }

      if (shouldAutoLogin) {
        // handle auto login
        handleGetCurrentUser(true);
      } else {
        // show manual login
        setIsLoading(false);
        setTimeout(() => {
          dispatch(setAuth({ showSplash: false }));
        }, minimumSplashDuration);
      }
    }

    // hide splash if necessary
    // if (type === 'logout') {
    //   dispatch(setAuth({ showSplash: false }));
    // }
  }, [api, type, auth]);

  return (
    <StyledAuthModal>
      {/* Login */}
      {type === 'login' &&
        <>
          <Heading heading="Log in to SparrowHub" subheading="Enter your store details to log in." />
          {errorMessage &&
            <Alert type={AlertType.Urgent} icon={AlertIcon.ExclamationRed}>
              <p>{errorMessage}<br/>Please try again or contact Rival Software <a href="mailto:support@sparrowhub.com.au?subject=SparrowHub%20Support%20Request" target="_blank" rel="noreferrer">here</a>.</p>
            </Alert>
          }
          <form>
            <InputField type="text" id="email" label="Email" value={email} onChange={(e: ChangeEvent) => setEmail((e.target as HTMLInputElement).value)} />
            <InputField type="password" id="password" label="Password" value={password} onChange={(e: ChangeEvent) => setPassword((e.target as HTMLInputElement).value)} />
            <Button type={ButtonType.Primary} text="Log In" onClick={handleLogin} loading={isLoading} submit />
          </form>
        </>
      }

      {type === 'location' &&
        <>
          <Heading heading="Select a store" subheading="Select your store to display the prescription queue." />
          <div className="select_location_grid">
            {auth.partnerLocations!.filter(location => location.is_enabled).map((location: Location, i: number) => {
              return (<Button type={ButtonType.Primary} text={location.name} onClick={() => handleSelectLocation(location)} key={location.name} />)
            })}
          </div>
        </>
      }
    </StyledAuthModal>

    // <StyledAuthModal>
    //   {/* Logout */}
    //   {type === 'logout' &&
    //     <>
    //       <Heading heading="Log out" subheading="You have been successfully logged out." />
    //       <NavLink to="/" className="bold">Return home</NavLink>
    //     </>
    //   }
      
    //   {/* Auto logout */}
    //   {type === 'logout-auto' &&
    //     <>
    //       <Heading heading="Session expired" subheading="Your session has expired. Please log in again to continue." />
    //       <NavLink to="/" className="bold">Log in</NavLink>
    //     </>
    //   }
    // </StyledAuthModal>
  );
}

const StyledAuthModal = styled.div`
  width: 100%;
  padding: 83px 52px;
  display: flex;
  flex-direction: column;

  border-radius: 6px;
  background: white;
  box-shadow: 0 0 5px 0 rgba(180, 180, 180, 0.25);

  h2 {
    font-size: 2.25rem; // 36px
  }

  p, a {
    font-size: 1rem; // 16px
    line-height: 1.3;
  }

  form, .select_location_grid {
    margin-top: 20px;
  }

  label {
    margin-top: 18px;
    font-size: 0.875rem; // 14px
    display: block;
  }

  input {
    display: block;
    width: 100%;
  }

  .Alert {
    margin-top: 15px;
    margin-bottom: -20px;
  }

  .forgot_password {
    display: inline-block;
    font-size: 0.8125rem; // 13px
  }

  .Button_primary {
    margin-top: 55px;
  }

  .Button_secondary {
    margin-top: 25px;
  }

  .select_location_grid {
    display: grid;
    grid-gap: 25px 14px;
    gap: 25px 14px;
    grid-template-columns: 1fr 1fr;

    button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 230px;
      height: 128px;
      margin-top: 0;
      padding: 30px;
    }
  }
`