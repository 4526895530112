import { FunctionComponent } from "react";
import { Navigate, To } from "react-router-dom";

import { useAppSelector } from '../store/hooks';

type ProtectedRouteProps = {
  userRequired?: boolean
  locationRequired?: boolean
  redirectPath?: To
  children: any
}

export const ProtectedRoute: FunctionComponent<ProtectedRouteProps> = ({ userRequired, locationRequired, redirectPath, children }) => {
  const auth = useAppSelector((state) => state.dashboard.auth);

  if (
    (userRequired && auth.user === null) ||
    (locationRequired && auth.location === null)
  ) {
    return <Navigate to={redirectPath!} replace />;
  }

  return children;
}

ProtectedRoute.defaultProps = {
  userRequired: true,
  locationRequired: true,
  redirectPath: '/'
}