import { FunctionComponent, useEffect } from "react";
import { Navigate, Params, useParams } from "react-router-dom";
import { useAppDispatch } from "../store/hooks";
import { setAuth } from "../store/dashboardSlice";

type HomePageProps = {
  
}

export const HomePage: FunctionComponent<HomePageProps> = ({ }) => {
  const dispatch = useAppDispatch();
  const { locationCode }: Readonly<Params<string>> = useParams();

  useEffect(() => {
    if (locationCode) {
      dispatch(setAuth({ locationCode }));
    }
  }, [])
  
  return (
    <Navigate to="/login" />
  );
}
