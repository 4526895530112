import { FunctionComponent } from 'react';
import styled from 'styled-components';

import rivalLogo from '../assets/images/logos/Rival.png';

/**
 * footer copyright
 * 
 * @returns string
 */
function Copyright(props: any): React.ReactElement {
  return (
    <p className="medium">
      &copy;{' '}{new Date().getFullYear()}{' '}
      <a color="inherit" href="https://sparrowhub.com.au/" target="_blank" rel="noreferrer">
        SparrowHub
      </a>
      {'. All rights reserved.'}
    </p>
  );
}

type FooterProps = {
  
}

export const Footer: FunctionComponent<FooterProps> = ({ }) => {
  return (
    <StyledFooter>
      <div className="footer">
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Copyright />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <p className="medium">Made by</p>
            <a href="https://rivalsoftware.com.au/" target="_blank" rel="noreferrer" style={{ height: '20px' }}>
              <img src={rivalLogo} alt="Rival Software" />
            </a>
        </div>
      </div>
    </StyledFooter>
  );
}

const StyledFooter = styled.footer`
  background: white;
  box-shadow: 0 -2px 4px 0 rgba(0, 0, 0, 0.1);
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;

  .footer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
    padding: 15px 20px;

    p {
      margin: 0;
      font-size: 0.625rem; // 10px
      display: inline;
    }

    a {
      font-size: 0.625rem; // 10px
      text-decoration: underline;
    }

    img {
      margin-left: 5px;
      height: 20px;
      width: auto;
    }

    .dummyDataToggle {
      display: block;
      margin-top: 8px;
      text-decoration: underline;
      cursor: pointer;
    }
  }
`