import { FunctionComponent } from "react";
import { styled } from "styled-components";

import { AuthModal } from "../components/AuthModal";
import { Footer } from "../components/Footer";


type SelectStorePageProps = {
  
}

export const SelectStorePage: FunctionComponent<SelectStorePageProps> = ({ }) => {
  return (
    <StyledSelectStorePage>
      <AuthModal type="location" />
      <Footer />
    </StyledSelectStorePage>
  );
}

const StyledSelectStorePage = styled.div`
  width: 578px;
  margin: auto;
`