import { createContext, useContext, FunctionComponent } from "react";
import axios from "axios";
import { QueueDashboardApiInterface, QueueDashboardApiFactory } from "sparrowhub-client-axios";

// init context
const ApiContext = createContext<QueueDashboardApiInterface | null>(null);

type ApiProviderProps = {
  children: any,
}

export const ApiProvider: FunctionComponent<ApiProviderProps> = ({ children }) => {
  // get endpoint URL from .env variables
  let basePath = undefined;
  if (
    process.env.REACT_APP_BACKEND_API_PROTOCOL && 
    process.env.REACT_APP_BACKEND_API_HOST && 
    process.env.REACT_APP_BACKEND_API_PORT && 
    process.env.REACT_APP_BACKEND_API_PREFIX
  ) {
    basePath = `${process.env.REACT_APP_BACKEND_API_PROTOCOL}://${process.env.REACT_APP_BACKEND_API_HOST}:${process.env.REACT_APP_BACKEND_API_PORT}/${process.env.REACT_APP_BACKEND_API_PREFIX}`;
  }

  const axiosInstance = axios.create({
    baseURL: basePath,
    withCredentials: true
  });

  const api: QueueDashboardApiInterface = QueueDashboardApiFactory(undefined, basePath, axiosInstance);

  return (
    <ApiContext.Provider value={ api }>
      { children }
    </ApiContext.Provider>
  );
}

// Api hook
export function useApi(): QueueDashboardApiInterface {
  const apiContext = useContext(ApiContext);

  if (!apiContext) {
    throw new Error("ApiProvider has returned context as null");
  }

  return apiContext;
}