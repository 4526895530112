import { PayloadAction, createSlice } from '@reduxjs/toolkit'

import { FilteredUser, Location } from 'sparrowhub-client-axios';

// ----------------------------------------------------------- TYPES

export interface Auth {
  user: FilteredUser | null
  location: Location | null
  locationCode: string | null
  partnerLocations: Array<Location>
  showSplash: boolean
}

export interface DashboardState {
  auth: Auth
}

export interface IIndexable<T = any> {
  [key: string]: T
}

// ----------------------------------------------------------- INITIAL STATE

const initialState: DashboardState = {
  auth: {
    user: null,
    location: null,
    locationCode: null,
    partnerLocations: [],
    showSplash: true
  }
}

// ----------------------------------------------------------- UTIL METHODS


// ----------------------------------------------------------- SLICE

export const dashboardSlice = createSlice({
  name: 'dashboard',
  initialState: initialState,
  reducers: {
    resetState: (state) => {
      state.auth = initialState.auth;
    },
    setAuth: (state, action: PayloadAction<Partial<Auth>>) => {
      for (const [key, value] of Object.entries(action.payload)) {
        (state.auth as IIndexable)[key] = value;
      }
    },
  },
})

export const {
  resetState,
  setAuth
} = dashboardSlice.actions

export default dashboardSlice.reducer
