import { FunctionComponent } from "react";
import styled from 'styled-components';

import { QueueElementNumber } from "sparrowhub-client-axios";

type OrderProps = {
  order: QueueElementNumber
  small?: boolean
}

export const Order: FunctionComponent<OrderProps> = ({ order, small }) => {
  return (
    <StyledOrder className={`Order ${order.queue_status_code} ${small && 'small'}`}>
      <div className="Order_text">
        <p className="Order_number bold">{order.queue_element_number}</p>
      </div>
    </StyledOrder>
  );
}

Order.defaultProps = {
  small: false
}

const StyledOrder = styled.div`
  width: 300px;
  height: 115px;
  border-radius: 115px;
  background-color: #D0E3E9;
  color: #056E76;

  display: flex;
  align-items: center;

  padding: 0 40px;
  break-inside: avoid;

  transform-origin: top left;

  transition:
    background-color 0.6s cubic-bezier(0.65, 0, 0.35, 1),
    transform 0.6s cubic-bezier(0.65, 0, 0.35, 1);

  .Order_text {
    text-align: center;
    width: 100%;

    .Order_number {
      font-size: 3.375rem; // 54px
      margin: 0;
    }
  }

  &.awaiting_collection, &.awaiting_collection_transition {
    background-color: #004259;
    color: white;
  }

  &.small {
    transform: scale(0.8);
  }
`;
