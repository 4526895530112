import { FunctionComponent } from 'react';
import styled from 'styled-components';
import { colours } from '../assets/css/variables';

import ExclamationIcon from '../assets/images/icons/Alert.svg';
import ExclamationRedIcon from '../assets/images/icons/AlertRed.svg';
// import SuccessIcon from '../assets/images/icons/Success.svg';
// import DollarIcon from '../assets/images/icons/DollarRed.svg';
// import PersonIcon from '../assets/images/icons/PersonOrange.svg';
// import NotesIcon from '../assets/images/icons/NotesBlue.svg';

export enum AlertType {
  Urgent = "urgent",
  Important = "important",
  Positive = "positive",
  PositiveSecondary = "positiveSecondary",
  Notes = "notes",
}

export enum AlertIcon {
  Exclamation = "exclamation",
  ExclamationRed = "exclamation-red",
  Tick = "tick",
  Dollar = "dollar",
  Person = "person",
  Notes = "notes",
}

type AlertProps = {
  type: AlertType
  children: any
  small?: boolean
  successModal?: boolean
  icon?: AlertIcon
}

export const Alert: FunctionComponent<AlertProps> = ({ type, children, small, successModal, icon }) => {
  const iconSrc = (): string => {
    if (icon) {
      // return explicitly specified icon
      switch (icon) {
        case AlertIcon.Exclamation:
          return ExclamationIcon;
        case AlertIcon.ExclamationRed:
          return ExclamationRedIcon;
        // case AlertIcon.Tick:
        //   return SuccessIcon;
        // case AlertIcon.Dollar:
        //   return DollarIcon;
        // case AlertIcon.Person:
        //   return PersonIcon;
        // case AlertIcon.Notes:
        //   return NotesIcon;
        default:
          return ExclamationIcon;
      }
    } else {
      // else return default icon for type
      switch (type) {
        // case AlertType.Urgent:
          // return DollarIcon;
        case AlertType.Important:
          return ExclamationIcon;
        // case AlertType.Positive:
        //   return SuccessIcon;
        // case AlertType.PositiveSecondary:
        //   return SuccessIcon;
        // case AlertType.Notes:
        //   return NotesIcon;
        default:
          return '';
      }
    }
  }


  return (
    <StyledAlert className={`Alert Alert_${type} ${small ? 'Alert_small' : ''} ${successModal ? 'Alert_success' : ''}`}>
      <img src={iconSrc()} alt="Alert" />
      <div>
        {children}
      </div>
    </StyledAlert>
  );
}

const StyledAlert = styled.div`
  &.Alert {
    border-radius: 6px;
    box-shadow: 0 0 4px 0 rgba(232, 232, 232, 0.25);
    /* height: 75px; */
    display: flex;
    align-items: center;
    padding: 13px 18px 13px 16px;

    p {
      margin: 0 !important;
      /* font-size: 0.8125rem !important; // 13px */
    }

    img {
      width: 29px !important;
      height: auto !important;
      margin-right: 14px;
    }
    
    /* TYPES */

    &.Alert_urgent {
      background-color: ${colours.urgentContainer};
      color: ${colours.urgent};

      p, a {
        color: ${colours.urgent};
      }
    }

    &.Alert_important {
      background-color: ${colours.importantContainer};
      color: ${colours.important};

      p, a {
        color: ${colours.important};
      }
    }
    
    &.Alert_positive {
      background-color: ${colours.positiveContainer};
      color: ${colours.positive};

      p, a {
        color: ${colours.positive};
      }
    }
    
    &.Alert_positiveSecondary {
      background-color: transparent;
      color: ${colours.positive};

      p, a {
        color: ${colours.positive};
      }
    }
    
    &.Alert_notes {
      background-color: #E2F3F6;
      color: #25A8C5;

      p, a {
        color: #25A8C5;
      }
    }

    /* SMALL */
    &.Alert_small {
      padding: 6px 10px;

      img {
        width: 18px !important;
        height: auto !important;
        margin-right: 7px;
      }
    }

    /* SUCCESS MODAL */
    &.Alert_success {
      margin: -19px 0 -33px 0 !important;

      p {
        font-size: 1rem; // 16px
      }
    }
  }
`