import { FunctionComponent } from "react";
import { styled } from "styled-components";

import { AuthModal } from "../components/AuthModal";
import { Footer } from "../components/Footer";


type LoginPageProps = {
  
}

export const LoginPage: FunctionComponent<LoginPageProps> = ({ }) => {
  return (
    <StyledLoginPage>
      <AuthModal type="login" />
      <Footer />
    </StyledLoginPage>
  );
}

const StyledLoginPage = styled.div`
  width: 578px;
  margin: auto;
`