import { BrowserRouter, Routes, Route } from 'react-router-dom';
import styled from 'styled-components';

import { ApiProvider } from './context/ApiProvider';

import { useAppSelector } from './store/hooks';

import { HomePage } from './pages/HomePage';
import { LoginPage } from './pages/LoginPage';
import { SelectStorePage } from './pages/SelectStorePage';
import { QueuePage } from './pages/QueuePage';

import { Splash } from './components/Splash';
import { ProtectedRoute } from './components/ProtectedRoute';

function App() {
  const auth = useAppSelector((state) => state.dashboard.auth);

  return (
    <ApiProvider>
      <StyledApp className="App">
        <BrowserRouter>
          <Splash show={auth.showSplash} />

          <Routes>
            <Route path="/:locationCode?" element={<HomePage/>} />
            <Route path="/login" element={<LoginPage/>} />
            <Route path="/select-store" element={<ProtectedRoute locationRequired={false}><SelectStorePage/></ProtectedRoute>} />
            <Route path="/queue" element={<ProtectedRoute><QueuePage/></ProtectedRoute>} />
          </Routes>
        </BrowserRouter>
      </StyledApp>
    </ApiProvider>
  );
}

export default App;

const StyledApp = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  background: #F8F8F8;
`;