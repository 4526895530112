import { FunctionComponent } from "react";

import { Header } from "../components/Header";
import { Orders } from "../components/Orders";

type QueuePageProps = {
  
}

export const QueuePage: FunctionComponent<QueuePageProps> = ({ }) => {
  return (
    <Orders />
  );
}
