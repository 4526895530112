import { FunctionComponent, useEffect, useState } from "react";
import styled from 'styled-components';
import useSound from 'use-sound';

import { useApi } from "../context/ApiProvider";
import { useAppSelector, useAppDispatch } from "../store/hooks";
import { resetState } from "../store/dashboardSlice";
import { QueueElementNumber, QueueStatuses } from "sparrowhub-client-axios";

import { Order } from "./Order";

import notification from '../assets/audio/notification.wav';

const useLocalDummyData = false;
const useDummyPatientNames = false;
const useDummyAlphaNumbers = true;
const numDummyOrders = 13;

const initialDummyOrders = [
  {
    queue_status_code: QueueStatuses.New,
    queue_element_number: useDummyAlphaNumbers ? 'IVRS' : '00013',
  },
  {
    queue_status_code: QueueStatuses.New,
    queue_element_number: useDummyAlphaNumbers ? 'XHUD' : '00014',
  },
  {
    queue_status_code: QueueStatuses.New,
    queue_element_number: useDummyAlphaNumbers ? 'OISU' : '00015',
  },
  {
    queue_status_code: QueueStatuses.New,
    queue_element_number: useDummyAlphaNumbers ? 'AUID' : '00016',
  },
]

const newDummyOrder = {
  queue_element_number: useDummyAlphaNumbers ? 'HUIS' : '00017',
}

const showDevControls = false;

const ordersPerPageDefault = 10;
const ordersPerPageSmall = 18;

type OrdersProps = {

}

export const Orders: FunctionComponent<OrdersProps> = ({ }) => {
  const api = useApi();
  const dispatch = useAppDispatch();
  const auth = useAppSelector((state) => state.dashboard.auth);
  
  // generate dummy data
  let tempOrders: Array<QueueElementNumber> = [];
  let dummyOrders: Array<QueueElementNumber> = [];
  if (useLocalDummyData) {
    if (numDummyOrders <= initialDummyOrders.length) {
      tempOrders = initialDummyOrders.slice(0, numDummyOrders) as Array<QueueElementNumber>;
    } else {
      while (tempOrders.length < numDummyOrders) {
        tempOrders = [
          ...tempOrders,
          ...initialDummyOrders
        ] as Array<QueueElementNumber>
      }
      tempOrders = tempOrders.slice(0, numDummyOrders) as Array<QueueElementNumber>;
    }
    dummyOrders = JSON.parse(JSON.stringify(tempOrders));
    dummyOrders.forEach((order: QueueElementNumber, i: number) => {
      order.id = i;
    });
    (window as any).orders = dummyOrders;
  }

  const [orders, setOrders] = useState<Array<QueueElementNumber>>(useLocalDummyData ? dummyOrders : []);
  const [showOrders, setShowOrders] = useState(true);
  
  const [showNotification, setShowNotification] = useState(false);
  const [showNotificationContent, setShowNotificationContent] = useState(0);
  const [showNewOrder, setShowNewOrder] = useState(true);
  const [connecting, setConnecting] = useState(false);
  
  const [pageIndex, setPageIndex] = useState(0);

  const [playNotification] = useSound(notification, { volume: 0.6 });

  // computed
  const newOrder = (): QueueElementNumber | null => {
    if (orders.length === 0) return null
    return orders[orders.length - 1];
  }

  const currentPage = (type: 'queue' | 'pickup'): number => {
    if ((window as any).init) {
      if (showNotification) {
        return numPages(type) - 1;
      } else {
        // return (window as any).pageIndex % numPages();
        return pageIndex % numPages(type);
      }
    } else {
      return 0;
    }
  }

  const numOrders = (type: 'queue' | 'pickup'): number => {
    if ((window as any).init) {
      return (window as any).orders
        .filter((order: QueueElementNumber) => (order.queue_status_code === QueueStatuses.AwaitingCollection) === (type === 'pickup'))
        .length;
    } else {
      return 0;
    }
  }

  const displaySmallOrders = (type: 'queue' | 'pickup'): boolean => {
    return numOrders(type) > ordersPerPageDefault;
  }

  const ordersPerPage = (type: 'queue' | 'pickup'): number => {
    return displaySmallOrders(type) ? ordersPerPageSmall : ordersPerPageDefault;
  }
  
  const numPages = (type: 'queue' | 'pickup'): number => {
    if ((window as any).init) {
      return Math.ceil(numOrders(type) / ordersPerPage(type));
    } else {
      return 1;
    }
  }

  const pageArray = (type: 'queue' | 'pickup'): Array<number> => {
    const array = [];
    for (let i = 0; i < numPages(type); i++) {
      array.push(i);
    }
    return array;
  }

  const currentPageOrders = (type: 'queue' | 'pickup'): Array<QueueElementNumber> => {
    let currentPageOrders = orders
      .filter(order => (order.queue_status_code === QueueStatuses.AwaitingCollection) === (type === 'pickup'))
      .slice(currentPage(type) * ordersPerPage(type), ((currentPage(type) + 1) * ordersPerPage(type)))

    // return without duplicates by queue element number
    return currentPageOrders.filter((value, index, self) =>
      index === self.findIndex((t) => (
        t.queue_element_number === value.queue_element_number
      ))
    )
  }

  // methods
  const testQueue = (): void => {
    const arrayToAdd = [
      {
        id: 1,
        queue_status_code: QueueStatuses.New,
        queue_element_number: '00001',
      },
      {
        id: 2,
        queue_status_code: QueueStatuses.New,
        queue_element_number: '00002',
      },
      {
        id: 3,
        queue_status_code: QueueStatuses.New,
        queue_element_number: '00003',
      },
      {
        id: 4,
        queue_status_code: QueueStatuses.New,
        queue_element_number: '00004',
      },
      {
        id: 5,
        queue_status_code: QueueStatuses.New,
        queue_element_number: '00005',
      },
      {
        id: 6,
        queue_status_code: QueueStatuses.New,
        queue_element_number: '00006',
      },
    ]
    const shouldInitQueue = (window as any).newOrderQueue.length === 0;
    arrayToAdd.forEach((orderToAdd: QueueElementNumber, i: number) => {
      (window as any).newOrderQueue.push(orderToAdd);
    })
    if (shouldInitQueue) handleAddOrderFromQueue();
  }

  const toggleNotification = (type: 'queue' | 'pickup'): void => {
    if (showNotification === false) {
      if (currentPage(type) !== numPages(type) - 1) {
        setShowOrders(false);
        setTimeout(() => {
          setShowOrders(true);
          setShowNotification(true);
        }, 600);
      } else {
        setShowNotification(true);
      }
    } else {
      setShowNotification(false);
    }
  }

  const addOrder = (order: QueueElementNumber): void => {
    let mutatedOrders = JSON.parse(JSON.stringify((window as any).orders));
    mutatedOrders.push(order);
    setOrders(mutatedOrders);
    (window as any).orders = mutatedOrders;
  }
  
  const removeOrder = (index: number): void => {
    let mutatedOrders = JSON.parse(JSON.stringify(orders));
    mutatedOrders.splice(index, 1);
    setOrders(mutatedOrders);
    (window as any).orders = mutatedOrders;
  }
  
  const handleAddOrder = (order: QueueElementNumber): void => {
    // reset state
    // setShowNewOrder(false);
    // setShowNotificationContent(0);
    // show notification panel and animate page change if necessary
    if (currentPage('queue') !== numPages('queue') - 1) {
      setShowOrders(false);
      setTimeout(() => {
        setShowOrders(true);
        setShowNotification(true);
      }, 600);
    } else {
      setShowNotification(true);
    }
    // add order to array
    setTimeout(() => { addOrder(order) }, 600);
    // show notification content
    setTimeout(() => {
      setShowNotificationContent(1);
      playNotification();
    }, 300);
    setTimeout(() => { setShowNotificationContent(2) }, 600);
    setTimeout(() => { setShowNotificationContent(3) }, 900);
    // show new order
    // setTimeout(() => { setShowNewOrder(true) }, 1500);

    // if order is changing states, transition and then remove old order
    const outdatedOrderIndex = (window as any).orders.findIndex((existingOrder: QueueElementNumber) => existingOrder.id === order.id && existingOrder.queue_status_code !== QueueStatuses.AwaitingCollection);
    if (outdatedOrderIndex !== -1) {
      // transition colour
      setTimeout(() => {
        const mutatedOrders = JSON.parse(JSON.stringify((window as any).orders));
        mutatedOrders[outdatedOrderIndex].queue_status_code = 'awaiting_collection_transition';
        setOrders(mutatedOrders);
        (window as any).orders = mutatedOrders;
      }, 2000);
      // remove old order
      setTimeout(() => {
        const mutatedOrders = JSON.parse(JSON.stringify((window as any).orders));
        mutatedOrders.splice(outdatedOrderIndex, 1);
        setOrders(mutatedOrders);
        (window as any).orders = mutatedOrders;
      }, 6500);
    }

    setTimeout(() => {
      if ((window as any).newOrderQueue.length === 0) {
        // if no further orders in queue,
        // hide notification panel
        setShowNotification(false);
        setShowNotificationContent(0);
      } else {
        // else half reset state and add next order
        setShowNotificationContent(1);
        setTimeout(() => {
          handleAddOrderFromQueue();
        }, 500);
      }
    }, 7000);
  }

  const handleAddOrderFromQueue = (): void => {
    const oldestInQueueArray = (window as any).newOrderQueue.splice(0, 1);
    handleAddOrder(oldestInQueueArray[0]);
  }

  const initPolling = () => {
    (window as any).init = false;
    (window as any).syncing = false;
    (window as any).orders = [];
    (window as any).newOrderQueue = [];
    
    initPagination();

    handleGetOrders();
    const interval = setInterval(() => {
      handleGetOrders();
    }, pollInterval);
    (window as any).pollInterval = interval;
  }

  const initPagination = (): void => {
    (window as any).pageIndex = 0;

    const pageInterval = setInterval(() => {
      (window as any).pageIndex++;

      setShowOrders(false);
      setTimeout(() => {
        setPageIndex((window as any).pageIndex);
        setShowOrders(true);
      }, 600);

    }, 15 * 1000);
    (window as any).pageInterval = pageInterval;
  }

  const handleGetOrders = async () => {
    try {
      await getOrders();
    } catch (error) {
      // log error
      console.log('error retreiving orders')
      console.log(error);

      // clear interval
      clearInterval((window as any).pollInterval);
      (window as any).syncing = false;
      // restart polling
      const interval = setInterval(() => {
        handleGetOrders();
      }, pollInterval);
      (window as any).pollInterval = interval;
    }
  }

  const getOrders = async () => {
    if (
      !(window as any).syncing &&
      (window as any).pollInterval !== null &&
      (window as any).pageInterval !== null
    ) {
      (window as any).syncing = true;
      const response = await api.getDashboardQueueElementNumbersByLocationId(auth.location!.id);
      (window as any).syncing = false;
      
      // update component state if successful
      if (response.status === 200) {
        // filter new orders
        const collectionOrders: Array<QueueElementNumber> = JSON.parse((response.data as any).data);

        if ((window as any).init === false) {
          // if first update, set directly
          setOrders(collectionOrders);
          (window as any).orders = collectionOrders;
          (window as any).init = true; 
        } else {
          const existingOrders = [
            ...(window as any).orders,
            ...(window as any).newOrderQueue
          ]

          // if not first update
          // determine orders to add
          const arrayToAdd: Array<QueueElementNumber> = [];
          collectionOrders.forEach((newOrder: QueueElementNumber) => {
            const shouldAddOrder = existingOrders.findIndex((oldOrder: QueueElementNumber) => oldOrder.id === newOrder.id) === -1;
            if (shouldAddOrder) {
              if (newOrder.queue_status_code === QueueStatuses.AwaitingCollection) {
                // if order is awaiting collection, push to queue
                arrayToAdd.push(newOrder);
              } else {
                // if not, add order directly
                addOrder(newOrder);
              }
            }
          })

          // determine orders to remove or update
          const arrayToRemove: Array<QueueElementNumber> = [];
          existingOrders.forEach((oldOrder: QueueElementNumber) => {
            // const shouldRemoveOrder = collectionOrders.findIndex((newOrder: QueueElementNumber) => newOrder.id === oldOrder.id) === -1;
            const newVersion = collectionOrders.find((newOrder: QueueElementNumber) => newOrder.id === oldOrder.id);
            if (newVersion === undefined) {
              // if order no longer present, remove it
              arrayToRemove.push(oldOrder);
            } else {
              // if order still present, check for status change
              if (oldOrder.queue_status_code !== newVersion.queue_status_code && newVersion.queue_status_code === QueueStatuses.AwaitingCollection) {
                // if order is awaiting collection, push to queue
                arrayToAdd.push(newVersion);
              }
            }
          })
          
          // remove orders
          if (arrayToRemove.length > 0) {
            const mutatedOrders = JSON.parse(JSON.stringify((window as any).orders));
            arrayToRemove.forEach((orderToRemove: QueueElementNumber) => {
              const indexToRemove = mutatedOrders.findIndex((order: QueueElementNumber) => order.id === orderToRemove.id);
              if (indexToRemove !== -1) mutatedOrders.splice(indexToRemove, 1);
            })
            setOrders(mutatedOrders);
            (window as any).orders = mutatedOrders;
          }

          // add new orders to queue
          if (arrayToAdd.length > 0) {
            const shouldInitQueue = (window as any).newOrderQueue.length === 0;
            arrayToAdd.forEach((orderToAdd: QueueElementNumber) => {
              (window as any).newOrderQueue.push(orderToAdd);
            })
            if (shouldInitQueue) handleAddOrderFromQueue();
          }
        }
      } else {
        // handle error
        console.log('error retreiving orders')
        console.log(response);
      }
    }
  }

  const handleLogOut = (): void => {
    // clear local storage
    localStorage.removeItem('SparrowHub_QueueDashboard_location');
    localStorage.removeItem('SparrowHub_QueueDashboard_user');

    // clear intervals
    clearInterval((window as any).pollInterval);
    clearInterval((window as any).pageInterval);
    (window as any).pollInterval = null;
    (window as any).pageInterval = null;

    // log out
    api.logoutUser()
      .then(response => {
        dispatch(resetState());
      })
      .catch(error => {
        console.log('error');
        console.log(error);
      })
  }

  // set up order polling
  const pollInterval = 10 * 1000;
  useEffect(() => {
    (async () => {
      if (!useLocalDummyData) {
        if ((window as any).init !== true) {
          initPolling();
          // (window as any).init = true;
        }
      } else {
        if ((window as any).init !== true) {
          initPagination();
          (window as any).init = true;
        }
      }
      // return () => clearInterval(interval);
    })();
  }, [ orders ]);

  return (
    <StyledOrders className="Orders">
      <div className="Orders_ready">
        {/* prescriptions in the queue */}

        {(['queue', 'pickup'] as Array<'queue' | 'pickup'>).map((type: 'queue' | 'pickup') => {
          return (
            <div className={`Orders_parent Orders_${type}`} key={type}>
              {type === 'queue' &&
                <h2>Prescriptions in the queue</h2>
              }
              {type === 'pickup' &&
                <h2>Prescriptions awaiting pickup</h2>
              }
              
              <div className={`Orders_grid elementTransitionFade ${displaySmallOrders(type) && 'small'} ${!showOrders && !showNotification && numPages(type) > 1 ? 'hidden' : 'visible'}`}>
                {currentPageOrders(type).map((order: QueueElementNumber, i: number) => {
                  return (
                    <div key={`order-${type}-${order.id}-${order.queue_status_code}`} className={`Order_container elementTransition ${(i === currentPageOrders(type).length - 1 && !showNewOrder) ? 'hidden' : 'visible'}`}>
                      <Order order={order} small={displaySmallOrders(type)} />
                    </div>
                  )
                })}
              </div>

              <div className={`Orders_pagination elementTransitionFade ${numPages(type) > 1 ? 'visible' : 'hidden'}`} >
                {pageArray(type).map((page: number) => {
                  return (
                    <div className={`Orders_paginationDot ${currentPage(type) === page ? 'active' : ''}`} key={`pagination_${type}_${page}`}></div>
                  )
                })}
              </div>
            </div>
          )
        })}
      </div>

      <div className={`Orders_notification ${showNotification ? 'visible' : 'hidden'}`}>
        {newOrder() !== null &&
          <>
            <h4 className={`elementTransition ${showNotificationContent >= 1 ? 'visible' : 'hidden'}`}>Your script is ready</h4>
            {/* <p className={`Order_name extrabold elementTransition ${showNotificationContent >= 2 ? 'visible' : 'hidden'}`}>Order number</p> */}
            <p className={`Order_number extrabold elementTransition ${showNotificationContent >= 3 ? 'visible' : 'hidden'}`}>{(newOrder() as any)?.queue_element_number}</p>
          </>
        }
      </div>

      <div className="hiddenControls">
        <p onClick={handleLogOut}>Log out</p>
      </div>

      {showDevControls &&
        <div className="Orders_dev">
          <button onClick={() => toggleNotification('queue')}>Toggle notif panel</button>
          {/* <button onClick={() => handleAddOrder(newDummyOrder as any)}>Add new order</button> */}
          {/* <button onClick={() => setOrders(initialDummyOrders)}>Reset orders</button> */}
          {/* <button onClick={() => removeOrder(0)}>Remove top order</button> */}
          <button onClick={testQueue}>Add multiple orders</button>
        </div>
      }
    </StyledOrders>
  );
}

const StyledOrders = styled.div`
  position: relative;
  background: white;
  flex-grow: 1;

  display: flex;
  overflow: hidden;

  .Orders_ready {
    width: 100%;
    display: flex;
    position: relative;
    /* padding: 0 68px; */

    .Orders_connectControls {
      margin: 200px auto;

      button {
        background: white;
        border: none;
        border-radius: 6px;
        /* width: 150px; */
        /* height: 80px; */
        font-size: 1.5rem;
        font-weight: 800;
        padding: 0.5rem 2rem;
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.2);
        cursor: pointer;

        &:hover {
          background: #F0F0F0;
        }
      }
    }

    h3 {
      color: white;
      /* margin: 91px 0 62px 107px; */
      margin: 91px 0 62px 0;
      text-align: center;
      width: 100%;
      position: absolute;
    }

    .Orders_parent {
      position: relative;
      height: 100%;
      width: 50%;

      h2 {
        font-size: 3.5rem; // 56px
        color: #056E76;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        margin: 70px 0 0 0;
        text-align: center;
      }

      &.Orders_pickup {
        border-left: 1px solid #C7C7C7;

        .Orders_paginationDot {
          background: #004259 !important
        }

        h2 {
          color: #004259;
        }
      }
    }

    .Orders_grid {
      position: relative;
      height: 100%;
      width: 100%;

      column-fill: auto;
      column-gap: 42px;
      column-count: 2;
      padding: 210px 160px 100px 160px;

      /* transition: margin-left 1s cubic-bezier(0.65, 0, 0.35, 1); */
      transition: opacity 0.6s cubic-bezier(0.65, 0, 0.35, 1),
                  column-count 0s 0.5s,
                  padding 0.6s cubic-bezier(0.65, 0, 0.35, 1);

      .Order_container {
        height: calc(115px + 35px);
        transition: height 1s cubic-bezier(0.65, 0, 0.35, 1);

        .Order {
          margin-bottom: 35px;
        }
      }

      &.small {
        column-count: 3;
        padding: 210px 68px 100px 68px;

        .Order_container {
          height: calc(92px + 30px);
        }
      }
    }
  }

  .Orders_pagination {
    position: absolute;
    bottom: 45px;
    left: 0;
    padding: 0 68px;
    /* margin: 0 calc((100vw - min(1846px, 100vw)) / 2); */
    margin: 0;
    width: min(1846px, 100vw);

    display: flex;
    gap: 20px;

    .Orders_paginationDot {
      width: 25px;
      height: 25px;
      border-radius: 100%;
      background: #056E76;
      
      opacity: 0.5;
      transform: scale(0.8);

      transition: opacity 0.6s cubic-bezier(0.65, 0, 0.35, 1),
                  transform 0.6s cubic-bezier(0.65, 0, 0.35, 1);

      &.active {
        opacity: 1;
        transform: scale(1);
      }
    }
  }

  .Orders_notification {
    position: absolute;
    height: 100%;
    right: 0;

    display: flex;
    gap: 85px;
    flex-direction: column; 
    align-items: center;
    justify-content: center;
    text-align: center;
    background: white;
    color: #004259;

    overflow: hidden;
    transition: width 1s cubic-bezier(0.65, 0, 0.35, 1);

    &.visible {
      width: calc(50% - 1px);
    }

    &.hidden {
      width: 0;
    }

    h4 {
      margin: 0 0 0 0;
      width: 600px;
    }

    .Order_name {
      font-size: 3.625rem; // 58px
      line-height: 1.3;
      margin: 0;
      width: 600px;
    }
    
    .Order_number {
      font-size: 9.375rem; // 150px
      margin: 0 0 0 0;
      width: 600px;
    }
  }

  .Orders_dev {
    position: fixed;
    /* bottom: 10px; */
    top: 10px;
    left: 10px;
    display: flex;
    flex-direction: column;
    gap: 5px;
  }

  .hiddenControls {
    position: absolute;
    z-index: 999;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    opacity: 0;
    transition: opacity 0.2s ease;

    &:hover {
      opacity: 1;
    }

    p {
      margin: 20px;
      background: black;
      color: white;
      border-radius: 10px;
      padding: 16px 24px;
      font-size: 1rem;
      font-weight: bold;
      cursor: pointer;
      
      &:hover {
        text-decoration: underline;
      }
    }
  }
`;
