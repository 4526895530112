import { FunctionComponent, useRef, useEffect } from 'react';
import { CSSTransition } from 'react-transition-group';

import styled from 'styled-components';

import sparrowhubLoader from "../assets/images/graphics/sparrowhub-loader.gif"

type SplashProps = {
  show: boolean
}

export const Splash: FunctionComponent<SplashProps> = ({ show }) => {
  const nodeRef = useRef(null);
  
  // use hook to watch show prop update
  // disable body scroll if Splash visible
  useEffect(() => {
    setOverflowHidden(show);
    return () => setOverflowHidden(false);
  }, [show]);

  const setOverflowHidden = (disableScroll: boolean): void => {
    document.body.style.overflow = disableScroll ? 'hidden' : 'unset';
  }

  return (
    <CSSTransition
      in={show}
      appear={true}
      nodeRef={nodeRef}
      timeout={{
        enter: 600,
        exit: 300
      }}
      classNames="splash"
      unmountOnExit
    >
      <StyledSplash className="Splash_overlay" ref={nodeRef}>
        <div className="Splash">
          <img src={sparrowhubLoader} alt="" />
        </div>
      </StyledSplash>
    </CSSTransition>
  );
}

const StyledSplash = styled.div`
  z-index: 999;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  background: rgb(255,255,255);
  background: radial-gradient(circle, rgba(255,255,255,1) 0%, rgba(230,230,230,1) 100%);

  .Splash {
    img {
      width: 150px;
      transition: opacity 1s ease;

      &:hover {
        opacity: 0;
      }
    }
  }
`